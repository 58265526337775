import {EVENTS} from '@helpers/analytics/events'
import {BrowseTaxonomies} from '@helpers/analytics/events/browse'
import {sendEvent} from '@helpers/analytics/sendEvents'
import isServerSide from '@helpers/misc/isServerSide'
import useEventsBaseProperties from '@hooks/useEventsBaseProperties'
import Container from '@packages/justo-parts/lib/components/Container'
import useIsAnalyticsV2Avail from '@page-components/AnalyticsV2/hooks/useIsAnalyticsV2Available'
import {NextRouter, withRouter} from 'next/router'
import {useEffect} from 'react'
import {Carousel} from 'react-responsive-carousel'

import Slide from './Slide'

import 'react-responsive-carousel/lib/styles/carousel.min.css'
import useLazyAnalyticsV2 from '@page-components/AnalyticsV2/hooks/useLazyAnalyticsV2'
import {INTERNAL_EVENTS} from '@page-components/AnalyticsV2/types/analyticsServicesTypes'
import styles from './styles.module.css'

interface SliderProps {
  router: NextRouter
  slides: any[]
  title: string
  fullWidth: boolean
  duration: number
}

const isCurrentDateInRange = (startDate?: Date, endDate?: Date): boolean => {
  const now = new Date()

  if (startDate && endDate) return startDate <= now && now <= endDate
  if (startDate) return startDate <= now
  if (endDate) return now <= endDate

  return false
}

const normalizeStartOfDay = (date: Date): Date => {
  const newDate = new Date(date)
  newDate.setHours(0, 0, 0, 0)
  return newDate
}

const normalizeEndOfDay = (date: Date): Date => {
  const newDate = new Date(date)
  newDate.setHours(23, 59, 59, 999)
  return newDate
}

const getActiveSliderItems = (slides: any): any => {
  return slides.filter(slide => {
    if (!slide.startDate && !slide.endDate) return true

    const startDate = slide.startDate ? normalizeStartOfDay(slide.startDate) : null
    const endDate = slide.endDate ? normalizeEndOfDay(slide.endDate) : null

    return isCurrentDateInRange(startDate, endDate)
  })
}

function Slider(props: Readonly<SliderProps>) {
  const {router, title, fullWidth, duration, slides = []} = props
  const eventBaseProperties = useEventsBaseProperties()
  const analyticsV2 = useLazyAnalyticsV2()
  const isAnalyticsV2Avail = useIsAnalyticsV2Avail()

  const onClickItem = (index: number) => {
    const {link, blankLinkTarget} = slides[index]
    if (!link) return
    if (isServerSide()) return
    if (blankLinkTarget) {
      window.open(link)
    } else if (link.startsWith('/')) {
      router.push(link)
    } else {
      window.location.href = link
    }

    if (!isAnalyticsV2Avail) {
      sendEvent<BrowseTaxonomies['bannerClicked']>(EVENTS.browse.bannerClicked, {
        clickedSlideLink: link,
        ...eventBaseProperties,
      })
    } else {
      analyticsV2.trackEventAsDefaultOnInternal(INTERNAL_EVENTS.bannerClicked, {
        clickedSlideLink: link,
      })
    }
  }

  const renderSlide = (slide: any, index: number) => <Slide {...slide} key={index} />
  const sliderItems = getActiveSliderItems(slides)

  const content = (
    <div className={styles.container}>
      {title ? <div className={styles.title}>{title}</div> : null}
      <Carousel
        emulateTouch
        showThumbs={false}
        showStatus={false}
        interval={duration || 3000}
        autoPlay
        stopOnHover
        infiniteLoop
        useKeyboardArrows
        onClickItem={onClickItem}
      >
        {sliderItems.map((slide, index) => renderSlide(slide, index))}
      </Carousel>
    </div>
  )

  useEffect(() => {
    if (!slides?.[0]?.link) return
    if (!isAnalyticsV2Avail) {
      sendEvent<BrowseTaxonomies['bannerViewed']>(EVENTS.browse.bannerViewed, {
        firstSlideLink: slides?.[0]?.link,
        ...eventBaseProperties,
      })
    } else {
      analyticsV2.trackEventAsDefaultOnInternal(INTERNAL_EVENTS.bannerViewed, {
        firstSlideLink: slides?.[0]?.link,
      })
    }
  }, [])

  if (fullWidth) return content
  return <Container>{content}</Container>
}

export default withRouter(Slider)
